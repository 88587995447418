<template>
  <div>
    <el-carousel height="1070px" :interval="5000">
      <el-carousel-item v-for="(item,index) in carousel" :key="index">
        <img :src="item.image" alt="" class="banimg" />
      </el-carousel-item>
    </el-carousel>
    <!-- <div v-for="(item,index) in listarr" :key="index">
      <div v-if="arr[index%8]==1">{{item}}</div>
    </div> -->
  </div>
</template>

<script>
export default {
  props: {
    carousel: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data() {
    return {

    }
  },
  components: {},
  mounted() {},
  methods: {

  }
}
</script>
<style scoped lang="less">
.banimg {
  width: 100%;
  height: 1080px;
}
</style>

<template>
  <div>
    <div class="backimg">
      <div class="recommend_box">
        <div class="top_box">
          <div class="title">官方查询</div>
          <div class="img_box"><img src="@/assets/page/of.png" alt="" /></div>
          <div class="counterfeiting flex">
            <a :href="url" target="_blank">
              <div class="left flex">
              <div class="imagebox">
                <img src="@/assets/page/ewm.png" alt="" />
              </div>
             
                <div class="textbox">
                  <div>防伪查询</div>
                  <div>Security check</div>
               </div>
            </div>
            </a>
            <a :href="seller_url" target="_blank">
              <div class="right flex">
              <div class="imagebox">
                <img src="@/assets/page/fdj.png" alt="" />
              </div>
              <div class="textbox">
                <div>经销商查询</div>
                <div>Dealer inquiry</div>
              </div>
            </div>
            </a>
          
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    url:{},seller_url:{}
  },
  data() {
    return {}
  },
  components: {},
  mounted() {},
  methods: {}
}
</script>
<style scoped lang="less">
.backimg {
  width: 100%;
  height: 500px;
  background: url('../../assets/page/bjt2.png') no-repeat no-repeat;
  background-size: cover;
  .recommend_box {
    margin: 0px 85px;
    .top_box {
      padding-top: 61px;

      .title {
        color: #fff;
        font-size: 24px;
      }
      .img_box {
        margin-top: 14px;
        width: 559px;
        height: 45px;
        img {
          width: 559px;
          height: 45px;
        }
      }
      .counterfeiting {
        margin-top: 78px;
        .left {
          width: 875px;
          height: 180px;
          cursor: pointer;
          background-color: #27645d;
          .imagebox {
            width: 60px;
            height: 60px;
            margin-left: 310px;
            margin-right: 42px;
            img {
              width: 60px;
              height: 60px;
            }
          }
          .textbox {
            // width: 60px;
            // height: 60px;
            div:nth-child(1) {
              color: #fff;
              font-size: 30px;
            }
            div:nth-child(2) {
              margin-top: 13px;
              color: #fff;
              font-size: 18px;
            }
          }
        }
        .right {
          width: 875px;
          height: 180px;
          cursor: pointer;
          background-color: #3e907c;
          .imagebox {
            width: 60px;
            height: 60px;
            margin-left: 310px;
            margin-right: 42px;
            img {
              width: 60px;
              height: 60px;
            }
          }
          .textbox {
            // width: 60px;
            // height: 60px;
            div:nth-child(1) {
              color: #fff;
              font-size: 30px;
            }
            div:nth-child(2) {
              margin-top: 13px;
              color: #fff;
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}
</style>

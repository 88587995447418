<template>
  <div>
    <div class="about_lyh">
      <div class="content_box">
        <div class="left">
          <img
            :src="aboutdata.image"
            alt=""
          />
        </div>
        <div class="right">
          <div class="title">关于莱茵河</div>
          <div class="brand">
            <img src="@/assets/page/pinpai.png" alt="" />
          </div>
          <div class="contentext">
           {{ aboutdata.memo }}
          </div>
          <div class="infotext"> {{ aboutdata.en_memo }}</div>
          <div class="flex">
            <div class="info">
              <div style="font-size: 18px"><i> {{ aboutdata.year }}</i>年</div>
              <div class="buttomtext">悠久历史和生产经验</div>
            </div>
            <div class="info">
              <div style="font-size: 18px"><i> {{ aboutdata.area }}</i>万公顷</div>
              <div class="buttomtext">森林提供板材源头</div>
            </div>
            <div class="info">
              <div style="font-size: 18px"><i> {{ aboutdata.output }}</i>万立方米</div>
              <div class="buttomtext">年产量</div>
            </div>
          </div>
          <div class="more" @click="ckgdclick">查看更多 ></div>
          <div class="mores"></div>
          <div class="bottmoad flex">
            <div class="shu"></div>
            <div class="name">耐磨<i>.</i>耐压<i>.</i>环保</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    aboutdata: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {}
  },
  components: {},
  mounted() {},
  methods: {
    ckgdclick() {
      this.$router.push({
        path:'/adam'
      })
    }
  }
}
</script>
<style scoped lang="less">
.about_lyh {
  width: 100%;
  height: 950px;
  background: url('../../assets/page/bjt.png') no-repeat center center;
  background-size: cover;

  .content_box {
    display: flex;
    // justify-content: space-between;
    margin: 0 85px;
    height: 100%;
    .left {
      margin-top: 100px;
      width: 650px;
      height: 780px;
      overflow: hidden;
      img {
        width: 650px;
        height: 780px;
        // transform: scale(1.1); 
        transition: all 0.6s;
      }
    }
    .left:hover img{
      transform: scale(1.2);
    }
    .right {
      width: 1014px;
      padding-left: 86px;
      position: relative;
      .title {
        margin-top: 152px;
        color: #000000;
        font-size: 24px;
      }
      .brand {
        margin-top: 14px;
        width: 354px;
        /* overflow: hidden; */
        height: 45px;
        img {
          width: 354px;
          height: 45px;
          /* //原本的图片的大小，图片原来的大小不变  */
         
        }
      }
      /* .brand:hover{
        transform: scale(1.2);
      } */
      .contentext {
        font-size: 16px;
        color: #666666;
        margin: 62px 0 53px;
      }
      .infotext {
        font-size: 16px;
        color: #666666;
        margin-bottom: 69px;
      }
      .info {
        i {
          font-size: 48px;
          font-weight: 900;
          color: #3e907c;
          margin-right: 15px;
        }
        .buttomtext {
          margin-top: 14px;
          font-size: 16px;
          color: #777777;
        }
      }
      .info:nth-child(2),
      .info:nth-child(3) {
        margin-left: 155px;
      }
      .more {
        width: 140px;
        margin-top: 90px;
        font-size: 20px;
        cursor: pointer;
        color: #3e907c;
        padding-bottom: 4px;
      }
      .mores{
        width: 83px;
        height: 1px;
        background-color: #3e907c;
      }
      .bottmoad {
        position: absolute;
        bottom: 72px;
        right: 150px;
        .shu {
          width: 222px;
          height: 1px;
          border: 1px solid #999999;
        }
        .name {
          padding-left: 15px;
          font-size: 18px;

          color: #777777;
          i {
            line-height: 25px;
            margin: 5px;
          }
        }
      }
    }
  }
}
</style>

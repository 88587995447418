<template>
  <div>
    <!-- 轮播图 -->
    <banner :carousel="pagedata.carousel"></banner>

    <!-- 关于莱茵河 -->
    <aboutlyh :aboutdata="pagedata.about"></aboutlyh>

    <!-- 视频 -->
    <video controls height="1080px" width="100%" :src="pagedata.video"></video>
    <!-- 推荐板材 -->
    <recommend :recommenddata="pagedata.panel_series" :panel="pagedata.panel"></recommend>

    <!-- 推荐家具 -->
    <indexfurniture :furnituredata="pagedata.home" :home_info="pagedata.home_info"></indexfurniture> 

    <!-- 防伪查询 -->
    <inquiry :url="pagedata.url" :seller_url="pagedata.seller_url"></inquiry>
  </div>
</template>

<script>
import banner from '@/components/PublicPage/banner.vue' //轮播图
import aboutlyh from '@/components/component/aboutlyh.vue'
import recommend from '@/components/component/recommend.vue'
import indexfurniture from '@/components/component/indexfurniture.vue'
import inquiry from '@/components/component/inquiry.vue'

import {indexdata} from '../service/request/homepage'
export default {
  
  data() {
    return {
      pagedata: {
        // 首页头部轮播图
        carousel: [],
        // 关于莱茵河
        aboutdata: { },
        // 板材推荐
        recommenddata: { },
        // 家具推荐
        furnituredata: {},
      }
    }
  },
  components: {
    banner,
    aboutlyh,
    recommend,
    indexfurniture,
    inquiry
  },
  mounted() {
    this.initdata()
  },
  methods: {
    // 初始数据
    initdata() {
      indexdata().then(res => {
        console.log(res);
        this.pagedata = res.msg
        // console.log(this.pagedata);
      })
    }
  }
}
</script>
<style scoped lang="less">
</style>

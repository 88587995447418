<template>
  <div>
    <div class="recommend_box">
      <div class="top_box">
        <div class="title">板材介绍</div>
        <div class="img_box"><img src="@/assets/page/tuijian.png" alt="" /></div>
        <div class="text">
          {{ panels.memo }}
        </div>
        <div class="texttwo">
          {{ panels.en_memo }}
        </div>
      </div>
      <div class="buttom_box flex-space-between" v-if="recommenddata">
        <div class="left">
          <div v-for="(item, index) in recommenddatas" :key="index" class="List" @click="itemclick(index)">
            <div class="name" :style="activeindex == index ? 'color:#000' : ''">
              <i>{{ index + 1 }}.</i>{{ item.name }}
            </div>
            <div class="explains" :style="activeindex == index ? 'color:#000' : ''">{{ item.en_name }}</div>
          </div>
        </div>
        <div class="right" @click="imgclick(recommenddatas[activeindex].id)">
          <img :src="recommenddatas[activeindex].index_image" alt="" />
        </div>
      </div>
      <div class="Ingenuity">匠心之作，追求品质的极致</div>
      <div class="more" @click="cgclick">更多板材></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    recommenddata: {},
    panel: {}
  },
  data() {
    return {
      Listarr: ['纯色系列', '木纹系列', '石纹系列'],
      activeindex: 0, //高亮
      panels: {},
      recommenddatas: []
    }
  },
  watch: {
    panel: {
      handler(newVal) {
        this.panels = newVal
      }
    },
    recommenddata(newVal) {
      this.recommenddatas = newVal
    }
  },
  components: {},
  mounted() {
   
  },
  methods: {

    cgclick() {
      this.$router.push({
        path: '/board',
      })
    },
    // 侧边高亮
    itemclick(index) {
      this.activeindex = index
    },
    // 板材详情
    imgclick(id) {
      this.$router.push({
        path: '/boarditem',
        query: {
          id
        }
      })
    }
  }
}
</script>
<style scoped lang="less">
.recommend_box {
  background-color: #fff;
  position: relative;
  .top_box {
    margin: 97px 85px 0;
    .title {
      color: #000000;
      font-size: 24px;
    }
    .img_box {
      margin-top: 14px;
      width: 559px;
      height: 45px;
      img {
        width: 559px;
        height: 45px;
      }
    }
    .text:nth-child(0) {
      margin-top: 60px;
    }
    .text:nth-child(2) {
      margin-top: 40px;
    }
    .text {
      color: #666666;
      font-size: 16px;
      margin-top: 60px;
    }
    .texttwo {
      color: #666666;
      font-size: 16px;
      margin-top: 40px;
    }
  }
  .buttom_box {
    margin: 77px 0px 0 85px;
    .left {
      .List {
        cursor: pointer;
        margin-top: 107px;
        .name {
          font-size: 34px;
          color: #999999;
          i {
            font-size: 20px;
            margin-right: 10px;
          }
        }
        .explains {
          margin-left: 26px;
          color: #999999;
          font-size: 16px;
        }
      }
      .List:nth-child(1) {
        margin-top: 45px;
      }
      .activeclass {
        color: #000000;
      }
    }
    .right {
      cursor: pointer;
      overflow: hidden;
      transition: all 1s;
      img {
        width: 1440px;
        height: 700px;
        transition: all 0.9s;
      }
    }
    .right img:hover {
      transition: all 0.9s;
      transform: scale(1.1);
      // transform:translateY(-15px);
    }
  }
  .Ingenuity {
    display: flex;
    justify-content: flex-end;
    height: 97px;
    line-height: 97px;
    font-size: 18px;
    color: #777777;
    margin-right: 85px;
  }
  .more {
    position: absolute;
    bottom: 113px;
    cursor: pointer;
    left: 85px;
    font-size: 20px;
    color: #3e907c;
    padding-bottom: 4px;
    border-bottom: 1px solid #3e907c;
  }
}
</style>

<template>
  <div>
    <div style="background-color: #fbf7f4">
      <div class="recommend_box">
        <div class="top_box">
          <div class="title">家具推荐</div>
          <div class="img_box"><img src="@/assets/page/fu.png" alt="" /></div>
          <div class="text" v-if="home_info">
            {{ home_infos.memo }}
          </div>
          <div class="texttwo" >
            {{ home_infos.en_memo }}
          </div>
        </div>
        <div class="buttom_box flex" v-if="furnituredata">
          <div class="itembox" v-for="(item, index) in furnituredata" :key="index" @click="itemclick(item.id)">
            <div class="titletext">{{ item.style_en_name }}</div>
            <div class="itemimg">
              <img style="object-fit: cover;" :src="item.image" alt="" />
              <div class="showbox">
                <div class="toptitle">{{ item.style_name }}</div>
                <div class="bottomtext">莱茵河家具</div>
                <div class="bottomtext">LAIYINHE  FURNITURE</div>
              </div>
            </div>
          </div>
        </div>
        <div class="more">
          <div>更多家具 ></div>
          <div class="shu2"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    furnituredata: {
    },
    home_info:{}
  },
  watch: {
    home_info: {
      handler(nav) {
        this.home_infos = nav
        // console.log(this.home_infos);
      }
    }
  },
  data() {
    return {
      home_infos:{}
    }
  },
  components: {},
  mounted() {},
  methods: {
    itemclick(id) {
      this.$router.push({
        path: '/furniture',
        query: {
          id,
          index:id
        }
      })
    }
  }
}
</script>
<style scoped lang="less">
.recommend_box {
  // background-color: #FBF7F4;
  // position: relative;
  margin: 0px 85px;
  .top_box {
    padding-top: 97px;

    .title {
      color: #000000;
      font-size: 24px;
    }
    .img_box {
      margin-top: 14px;
      width: 559px;
      height: 45px;
      img {
        width: 559px;
        height: 45px;
      }
    }
    .text:nth-child(0) {
      margin-top: 60px;
    }
    .text:nth-child(2) {
      margin-top: 40px;
    }
    .text {
      color: #666666;
      font-size: 16px;
      margin-top: 60px;
    }
    .texttwo {
      color: #666666;
      font-size: 16px;
      margin-top: 40px;
    }
  }
  .buttom_box {
    margin-top: 70px;
    // padding-bottom: 50px;
    .itembox {
      width: 562px;
      cursor: pointer;
      margin-right: 32px;
      background-color: #fbf7f4;
      .titletext {
        text-align: center;
        font-size: 16px;
        color: #aaaaaa;
        margin-bottom: 18px;
      }
      .itemimg {
        width: 562px;
        height: 810px;
        position: relative;
        img {
          width: 562px;
          height: 810px;
        }
        .showbox {
          width: 562px;
          height: 810px;
          position: absolute;
          top: 0;
          left: 0;
          // bottom: 0px;
          opacity: 0;
          background: rgba(255, 255, 255, 0.59);
          // background: rgba(255,255,255,0.39);
          text-align: center;
          transition: all 0.8s;
          .toptitle {
            width: 56px;
            margin: 114px auto 552px;
            color: #3e907c;
            font-size: 20px;
          }
          .bottomtext {
            color: #3e907c;
            font-size: 14px;
            margin-bottom: 10px;
          }
        }
      }
      .itemimg:hover {
        .showbox {
          transition: all 0.8s;
          opacity: 1;
        }
      }
    }
    .itembox:hover {
      transition: all 0.6s;
      transform: translateY(-15px);
    }
    .itembox:nth-child(3) {
      margin-right: 0;
    }
  }
  .more {
    height: 111px;
    width: 120px;
    cursor: pointer;
    font-size: 20px;
    color: #3e907c;
    margin-top: 55px;
    .shu2 {
      margin-top: 5px;
      width: 79px;
      height: 1px;
      background-color: #3e907c;
    }
  }
}
</style>
